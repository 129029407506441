<template>
  <List
    title="bundles"
    resource="bundles"
    :basePath="basePath"
    :fields="fields"
    ref="list"
    on-row-click="edit"
    data-provider="$authDataProvider"
    :search="true"
    search-placeholder="Search for a bundle by name"
    :requestParams="{ type: SUITE, nonDeleted: true }"
  >
  </List>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import List from "@/components/auth/list/List";
  import ArrayField from "@/components/auth/list/fields/ArrayField";
  import { BUNDLE_TYPES } from '@/components/auth/constants';

  export default {
    name: "BundlesList",
    components: {
      List,
    },
    mixins: [ModalNavigation, ConfirmationMixin, NotifyMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
    },
    data() {
      return {
        fields: [
          {
            name: 'name',
            title: 'bundle name',
            dataClass: 'align-top',
          },
          {
            name: ArrayField,
            title: 'applications',
            dataClass: 'align-top',
            switch: {
              source: 'apps',
              label: 'appName',
              columnView: true,
            }
          },
          {
            name: ArrayField,
            title: 'metaroles',
            dataClass: 'align-top',
            switch: {
              source: 'metaroles',
              label: 'name',
              columnView: true,
            },
          },
        ]
      }
    },
    created () {
      this.SUITE = BUNDLE_TYPES.SUITE;
    }
  }
</script>

<style scoped>

</style>
